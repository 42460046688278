import React from "react";
// import RightDiv from "./RightDiv.jsx";
import LeftDiv from "./LeftDiv.jsx";
import RightDiv from "../../assets/servicios/serviciosSide.png";

const Servicios = () => {
  return (
    <section
      className="w-full h-[2080px] pt-[70px] bg-[#FAFAFA] flex flex-col
     lg:h-[1330px] lg:pl-10"
    >
      <div
        data-aos="fade-down"
        data-aos-easing="ease-out-back"
        data-aos-duration="1000"
        className="flex flex-col items-start pl-6 pt-8"
      >
        <p className="text-[20px] md:text-[30px] font-thin text-center">
          ¿Qué ofrecemos?
        </p>
        <h2 className="text-[40px] md:text-[50px] text-[#DA5928] font-semibold">
          Servicios
        </h2>
      </div>
      <div className="flex justify-center xl:justify-around">
        <div>
          <LeftDiv />
        </div>
        <div
          data-aos="fade-left"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="hidden xl:flex items-center w-auto h-auto"
        >
          <img
            src={RightDiv}
            alt="Imagen aniversario del dia del diseño"
            className="w-full h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Servicios;
