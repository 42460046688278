import React from "react";
import animacion from "../../assets/servicios/GRAFICA_ANIMACION.png";
import web from "../../assets/servicios/WEB.png";
import marketing from "../../assets/servicios/MARKETING.png";
import uxui from "../../assets/servicios/UX-UI.png";

const LeftDiv = () => {
  return (
    <section className="">
      <div className="flex flex-col items-center pt-16 space-y-20">
        {/* Animación y Gráfica */}
        <div
          data-aos="fade-right"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="relative w-[270px] h-[370px] flex justify-between rounded-b-3xl box-shadow
          lg:w-[700px] lg:h-[150px] lg:rounded-b-none lg:rounded-r-3xl"
        >
          <div>
            <div
              className="absolute z-0 bg-[#FFB71B] h-24 w-[268px] rounded-b-full lg:h-[150px] 
            lg:w-24 lg:rounded-b-none lg:rounded-r-full"
            />
            <div className="absolute z-10 bottom-[310px] left-[72px] lg:top-[13px] lg:left-[0px]">
              <img
                src={animacion}
                alt="Icono animación"
                loading="lazy"
                className="w-32 h-32"
              />
            </div>
          </div>
          <div className="w-[580px] flex flex-col justify-center text-center pt-16 lg:pt-0 lg:mr-2">
            <h2 className="font-semibold text-[25px] lg:text-3xl">
              Gráfica / Animación
            </h2>
            <p className="text-[18px] lg:text-[20px] text-[#575757]">
              Creamos conceptos en imágenes, damos vida a las ideas con
              movimientos, fluidos e impactantes.
            </p>
          </div>
        </div>

        {/* UX / UI */}
        <div
          data-aos="fade-right"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="relative w-[270px] h-[370px] flex justify-between box-shadow rounded-b-3xl
          lg:w-[700px] lg:h-[150px] lg:rounded-b-none lg:rounded-r-3xl"
        >
          <div>
            <div
              className="absolute z-0 bg-[#F18638] h-24 w-[268px] rounded-b-full lg:h-[150px] 
            lg:w-24 lg:rounded-b-none lg:rounded-r-full"
            />
            <div className="absolute z-10 bottom-[310px] left-[72px] lg:top-[13px] lg:left-[0px]">
              <img
                src={uxui}
                alt="Icono de diseño grafico"
                loading="lazy"
                className="w-32 h-32"
              />
            </div>
          </div>
          <div className="w-[580px] flex flex-col justify-center text-center pt-16 lg:pt-0 lg:mr-2">
            <h2 className="font-semibold text-[25px] lg:text-3xl">UX / UI</h2>
            <p className="text-[18px] lg:text-[20px] text-[#575757]">
              Transformamos la experiencia del usuario a través de interfaces
              intutiuvas y atractivas
            </p>
          </div>
        </div>

        {/* Desarrollo Web */}
        <div
          data-aos="fade-right"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="relative w-[270px] h-[370px] flex justify-between box-shadow rounded-b-3xl
          lg:w-[700px] lg:h-[150px] lg:rounded-b-none lg:rounded-r-3xl"
        >
          <div>
            <div
              className="absolute z-0 bg-[#EC6525] h-24 w-[268px] rounded-b-full lg:h-[150px] 
            lg:w-24 lg:rounded-b-none lg:rounded-r-full"
            />
            <div className="absolute z-10 bottom-[310px] left-[72px] lg:top-[13px] lg:left-[0px]">
              <img
                src={web}
                alt="Icono UX / UI"
                loading="lazy"
                className="w-32 h-32"
              />
            </div>
          </div>
          <div className="w-[580px] flex flex-col justify-center text-center pt-16 lg:pt-0 lg:mr-2">
            <h2 className="font-semibold text-[25px] lg:text-3xl">
              Desarrollo Web
            </h2>
            <p className="text-[18px] lg:text-[20px] text-[#575757]">
              Construimos sitos personalizados y funcionales que se adapten a
              tus necesidades.
            </p>
          </div>
        </div>

        {/* Marketing */}
        <div
          data-aos="fade-right"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="relative w-[270px] h-[370px] flex justify-between box-shadow rounded-b-3xl
          lg:w-[700px] lg:h-[150px] lg:rounded-b-none lg:rounded-r-3xl"
        >
          <div>
            <div
              className="absolute z-0 bg-[#DA5928] h-24 w-[268px] rounded-b-full lg:h-[150px] 
            lg:w-24 lg:rounded-b-none lg:rounded-r-full"
            />
            <div className="absolute z-10 bottom-[310px] left-[72px] lg:top-[13px] lg:left-[0px]">
              <img
                src={marketing}
                alt="Icono de desarrollo web"
                loading="lazy"
                className="w-32 h-32"
              />
            </div>
          </div>
          <div className="w-[580px] flex flex-col justify-center text-center pt-16 lg:pt-0 lg:mr-2">
            <h2 className="font-semibold text-[25px] lg:text-3xl">
              Marketing Digital
            </h2>
            <p className="text-[18px] lg:text-[20px] text-[#575757]">
              Ofrecemos estrategias personalizadas para elevar tu marca y
              aumentar tu visibilidad en línea.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftDiv;
