import React from "react";
import nar from "../assets/nosotros/NAR.png";
import ivan from "../assets/nosotros/IVAN.png";
import cle from "../assets/nosotros/USER_01.png";
import fiamma from "../assets/nosotros/FiammaPrecopio.png";
import ivana from "../assets/nosotros/IvanaBalmaceda.png";
import suzzette from "../assets/nosotros/USER_02.png";
import historia1 from "../assets/nosotros/imgHistoria1.png";
import historia2 from "../assets/nosotros/imgHistoria2.png";
import AOS from "aos";
import "aos/dist/aos.css";
import ProfileTemplate from "./ProfileTemplate";

const Nosotros = () => {
  AOS.init({ once: true });

  return (
    <section className="w-full min-h-screen pt-28 flex flex-col justify-center bg-[#FFF] overflow-x-hidden">
      {/* Nuestro equipo */}
      <div className="px-6 md:px-[50px] lg:pl-[98px] lg:pr-[90px]">
        <div
          data-aos="fade-down"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="space-y-3 pb-3"
        >
          <h1 className="text-[#DA5928] text-xl font-semibold md:pt-[70px] lg:pt-[110px] lg:pb-[60px] md:text-3xl lg:text-[50px]">
            Conoce a nuestro equipo
          </h1>
          <p className="text-[#666666] text-lg lg:text-[25px]">
            Está compuesto por apasionados y talentosos profesionales de
            diversas disciplinas digitales. Unimos nuestra creatividad y
            pensamiento estratégico para crear experiencias digitales
            impactantes. Nos enorgullece mantenernos actualizados con las
            últimas tendencias y tecnologías, colaborando estrechamente con
            nuestros clientes para superar sus metas comerciales.
          </p>
        </div>
        <div
          className="flex flex-col items-center space-y-[20px] pt-[50px] lg:pt-[90px] pb-[40px] lg:pb-[90px]
          md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:place-items-center min-[1500px]:grid-cols-3"
        >
          {/* 1 NARCIZO */}
          <div
            data-aos="fade-right"
            data-aos-easing="ease-out-back"
            data-aos-duration="1000"
          >
            <ProfileTemplate
              img={nar}
              name="Nar Palma"
              job="Diseñador Gráfico"
              desc="“Todo proceso creativo empieza con un problema, para elaborar
            una solución inconsciente de la idea y liberar la imaginación”"
              link="https://www.behance.net/nar17"
            />
          </div>

          {/* 2 CLE */}
          <div
            data-aos="fade-right"
            data-aos-easing="ease-out-back"
            data-aos-duration="1500"
          >
            <ProfileTemplate
              img={cle}
              name="Clelia Mary"
              job="Diseñadora UX / UI"
              desc="“Combino arte y comunicación para crear imágenes impactantes que
            transmiten mensajes claros y poderosos”"
              link="https://www.behance.net/clemary"
            />
          </div>

          {/* 3 IVAN */}
          <div
            data-aos="fade-right"
            data-aos-easing="ease-out-back"
            data-aos-duration="2000"
          >
            <ProfileTemplate
              img={ivan}
              name="Iván Gentta"
              job="Desarrollador Web"
              desc="“Me apasiona crear experiencias unicas mediante web apps y
            seguir aprendiendo y superando desafios durante el proceso”"
              link="https://my-portfolio-ten-brown.vercel.app/"
            />
          </div>

          {/* 4 FIAMMA */}
          <div
            data-aos="fade-right"
            data-aos-easing="ease-out-back"
            data-aos-duration="1000"
          >
            <ProfileTemplate
              img={fiamma}
              name="Fiamma Precopio"
              job="Community manager"
              desc="“Apasionada por ayudar a las marcas a mejorar su comunicación y
            construir relaciones cercanas y duraderas con los consumidores.”"
              link="https://www.behance.net/fiammaprecopio/appreciated"
            />
          </div>

          {/* 5 SUZETTE */}
          <div
            data-aos="fade-right"
            data-aos-easing="ease-out-back"
            data-aos-duration="1500"
          >
            <ProfileTemplate
              img={suzzette}
              name="Suzette Zacamo"
              job="Marketing"
              desc="“Encontrar nuevas formas de conectar con los clientes,
            desarrollar campañas que sean efectivas y satisfagan las
            necesidades del mercado.”"
            />
          </div>

          {/* 6 IVANA */}
          <div
            data-aos="fade-right"
            data-aos-easing="ease-out-back"
            data-aos-duration="2000"
          >
            <ProfileTemplate
              img={ivana}
              name="Ivana Balmacera"
              job="Diseñadora Gráfica"
              desc="“Me apasiona el diseño grafico, y considero importante la
            comunicación visual ya que determina la forma en la que alguien
            es percibido”"
              link="https://www.behance.net/ivanabalmaceda"
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Seccion info en amarillo */}
      <div
        className="w-full min-h-0 bg-[#FFB71B] p-6 space-y-12 lg:space-y-[100px] md:p-10
      lg:pt-[150px] lg:pb-[160px] lg:pl-[100px] lg:pr-[90px]"
      >
        <p
          data-aos="fade-right"
          data-aos-easing="ease-out-back"
          className="font-bold text-[20px] tracking-wider md:drop-shadow md:text-3xl md:leading-[50px]
        lg:text-6xl lg:tracking-widest lg:leading-[55px] 2xl:text-6xl"
        >
          Somos un estudio creativo online independiente, nuestro trabajo abarca
          gráficos, branding, diseño web, experiencias digitales, animaciones,
          consultoría y estrategias de marketing.
        </p>
        <p
          data-aos="fade-right"
          data-aos-easing="ease-out-back"
          className="text-justify text-[17px] md:text-xl lg:text-3xl"
        >
          En krooma sabemos escuchar, analizar y conocer las necesidades de
          nuestros clientes para aportar de manera puntual soluciones creativas
          logrando una buena interacción y comunicación para que se sientan
          satisfechos y únicos con el resultado aportado.
        </p>
      </div>

      {/* Historia */}
      <div className="flex flex-col px-6 py-5 md:px-12 lg:py-12 bg-[#FAFAFA]">
        <h1
          data-aos="fade-down"
          data-aos-easing="ease-out-back"
          data-aos-duration="1000"
          className="text-[#DA5928] text-xl font-semibold pb-3 md:text-3xl lg:text-[50px]"
        >
          Historia
        </h1>
        <div className="h-auto text-justify lg:flex lg:justify-between">
          <div
            data-aos="fade-down"
            data-aos-easing="ease-out-back"
            data-aos-duration="1500"
            className="lg:w-[500px] xl:w-[600px] 2xl:w-[800px] xl:py-10"
          >
            <p className="text-[#666666] text-[15px] pb-2 md:text-xl lg:text-[25px] lg:leading-[35px] xl:leading-[37px]">
              Krooma nace en el año 2010, por la inquietud de dos jóvenes
              creativos con la capacidad de pensar más allá de los límites
              convencionales, su objetivo es aportar soluciones innovadoras de
              diseño, publicidad y marketing que redefinan los valores de sus
              clientes y aporten nuevas vías de comunicación logrando así
              sobresalir en su mercado.
            </p>
            <p className="text-[#666666] text-[15px] md:text-xl lg:text-[25px] lg:leading-[35px] xl:leading-[37px]">
              Nos destacamos por el amplio conocimiento de su equipo en cuanto a
              las nuevas tendencias del mercado y por tener una actitud
              proactiva en la toma de decisiones. Actualmente Krooma ha sabido
              hacerse un hueco en el mercado gracias a la calidad de sus
              soluciones creativas y al enfoque centrado en el cliente, que
              permitió una cercanía y empatía con cada uno de ellos. Somos un
              equipo entregado y apasionado por su trabajo y con una única meta:
              el éxito de sus clientes.
            </p>
          </div>
          <div
            data-aos="fade-left"
            data-aos-easing="ease-out-back"
            data-aos-duration="1500"
            className="hidden lg:flex relative w-1/2 h-auto"
          >
            <div className="absolute z-20 -top-16 left-32 xl:left-48 2xl:left-64">
              <div className="w-60 h-60 xl:w-72 xl:h-72">
                <img
                  src={historia1}
                  alt=""
                  className="w-full h-full drop-shadow-lg"
                />
              </div>
            </div>
            <div className="absolute w-60 h-60 xl:w-72 xl:h-72 rounded-full bg-[#FFB71B] z-10 left-48 xl:left-72 2xl:left-96 -top-2" />
            <div className="absolute z-20 left-48 xl:left-72 2xl:left-96 bottom-0">
              <div className="w-60 h-60 xl:w-72 xl:h-72">
                <img
                  src={historia2}
                  alt=""
                  className="w-full h-full drop-shadow-lg"
                />
              </div>
            </div>
            <div className="absolute w-60 h-60 xl:w-72 xl:h-72 rounded-full bg-[#FFB71B] z-10 left-32 xl:left-48 2xl:left-64 bottom-10" />
          </div>
        </div>
      </div>

      {/* Misión - Visión - Valores */}
      <div className="flex flex-col items-center px-6 pb-5 lg:pb-24 space-y-3 pt-5 lg:flex-row lg:space-y-0 lg:px-2 lg:space-x-3 lg:justify-evenly">
        <div
          // data-aos="fade-down"
          // data-aos-easing="ease-out-back"
          // data-aos-duration="1000"
          className="w-auto h-[300px] min-[375px]:w-[350px] min-[375px]:h-[250px] lg:h-[480px] xl:w-[400px] xl:h-[400px] flex flex-col items-center p-4 border rounded-lg shadow-md"
        >
          <h2 className="text-[#DA5928] font-semibold text-[25px] lg:text-[50px]">
            Misión
          </h2>
          <div className="w-full h-full flex items-center justify-center">
            <p className="text-[#666666] text-base text-center lg:text-[25px] lg:leading-8">
              Ayudar a las empresas a alcanzar sus objetivos a través de
              estrategias innovadoras, creativas y efectivas. Somos un agente
              que no solo crea publicidad, sino que da vida y forma a las ideas,
              proyectos y emociones.
            </p>
          </div>
        </div>
        <div
          // data-aos="fade-down"
          // data-aos-easing="ease-out-back"
          // data-aos-duration="1000"
          className="w-auto h-[300px] min-[375px]:w-[350px] min-[375px]:h-[250px] lg:h-[480px] xl:w-[400px] xl:h-[400px] flex flex-col items-center p-4 border rounded-lg shadow-md"
        >
          <h2 className="text-[#DA5928] font-semibold text-[25px] lg:text-[50px]">
            Visión
          </h2>
          <div className="w-full h-full flex items-center justify-center">
            <p className="text-[#666666] text-base text-center lg:text-[25px] lg:leading-8">
              Convertirnos en líderes en el mercado global en cuanto a diseño,
              publicidad y marketing, abarcando soluciones integrales, de esta
              forma, consolidarnos como un referente de calidad y creatividad en
              el mercado internacional.
            </p>
          </div>
        </div>
        <div
          // data-aos="fade-down"
          // data-aos-easing="ease-out-back"
          // data-aos-duration="1000"
          className="w-auto h-[300px] min-[375px]:w-[350px] min-[375px]:h-[250px] lg:h-[480px] xl:w-[400px] xl:h-[400px] flex flex-col items-center  p-4 border rounded-lg shadow-md"
        >
          <h2 className="text-[#DA5928] font-semibold text-[25px] lg:text-[50px]">
            Valores
          </h2>
          <div className="w-full h-full flex items-center justify-center">
            <p className="text-[#666666] text-base text-center lg:text-[25px] lg:leading-8">
              El respeto, la innovación, la creatividad y la pasión por lo que
              hacemos. Nuestro equipo trabaja dedicándose al detalle en cada uno
              de nuestros proyectos de la mano de sus clientes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nosotros;
