import React from "react";

const ProfileTemplate = (props) => {
  const { img, name, job, desc, link } = props;
  return (
    <div
      className="flex flex-col items-center w-[280px] h-auto pb-10
    lg:flex-row lg:w-[380px] min-[1200px]:w-[450px] lg:space-x-2 min-[1500px]:pb-20"
    >
      <img
        src={img}
        alt="imágen del integrante del equipo"
        className="w-44 h-44 lg:w-48 lg:h-48"
      />
      <div className="flex flex-col items-center lg:items-start lg:space-y-1">
        <p className="font-bold text-[16px]">{name}</p>
        <p className="font-thin">{job}</p>
        <p className="text-[#838383] text-[14px] text-center pb-2 lg:text-left w-[95%]">
          {desc}
        </p>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <button className="bg-[#2D2A26] rounded-lg text-[#FFFFFF] text-sm px-5 py-2 lg:px-8">
            Ver trabajos
          </button>
        </a>
      </div>
    </div>
  );
};

export default ProfileTemplate;
