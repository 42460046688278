import React from "react";
import { Link } from "react-router-dom";

const ProjectCardItem = ({ card, onClick }) => {
  return (
    <div className="relative" onClick={onClick}>
      <Link to={card.route}>
        <div className="max-w-[550px]">
          <div className="relative group">
            <img src={card.img} alt="Imagen de proyecto" loading="lazy" />
            <div className="absolute inset-0 flex items-end opacity-0 group-hover:opacity-100 group-hover:border-4 border-[#FFB71B] transition ease-out duration-300">
              <div className="w-full p-3 bg-white/60">
                <p className="md:text-xl">
                  <b>{card.title}</b>
                  {card.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectCardItem;
