import React, { useEffect, useState } from "react";

const CookiesDiv = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");
    if (isCookieAccepted) {
      setIsVisible(false);
    }
  }, []);

  const onHideCookies = () => {
    setIsVisible(false);
    localStorage.setItem("cookieAccepted", "true");
  };

  return (
    <div className="ml-2">
      {isVisible && (
        <div
          data-aos="fade-up"
          data-aos-duration="700"
          className={`w-full min-h-0 flex justify-center fixed bottom-0 right-0 bg-white p-4 lg:px-[90px] text-xs lg:text-[18px] z-50 transition-opacity ease-in-out duration-700 ${
            isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
          }`}
        >
          <div className="">
            <p>
              Nuestro sitio web utiliza cookies que son necesarias para su
              funcionamiento y para proporcionar características y servicios
              esenciales. También utilizamos cookies de análisis para recopilar
              información sobre cómo interactúas con nuestro sitio y cómo
              podemos mejorarlo.
            </p>
            <div className="flex justify-end space-x-4 md:space-x-8 pt-4">
              <button
                onClick={onHideCookies}
                className="w-auto h-auto py-2 px-4 border border-[#2D2A26] rounded-md bg-white"
              >
                Rechazar todas
              </button>
              <button
                onClick={onHideCookies}
                className="w-auto h-auto py-2 px-4 border border-[#2D2A26] rounded-md bg-[#2D2A26] text-white"
              >
                Aceptar todas
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiesDiv;
