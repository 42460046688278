import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/krooma/logoKrooma.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { Utils } from "../utils/Utils.jsx";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const { buttonSelected, setButtonSelected } = useContext(Utils);
  const location = useLocation();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleClickScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const getIndexFromPath = (path) => {
      if (path === "/nosotros") return 1;
      if (path === "/portafolio") return 2;
      if (path === "/contacto") return 3;
      return 0;
    };

    const selectedIndex = getIndexFromPath(location.pathname);
    const updatedButtonSelected = Array(buttonSelected.length).fill(false);
    updatedButtonSelected[selectedIndex] = true;
    setButtonSelected(updatedButtonSelected);
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <nav>
      <div className="w-full min-h-0 fixed z-[100] flex py-2 items-center bg-[#2D2A26E5]/90 drop-shadow-lg">
        {/* Menu Mobile */}
        <div onClick={handleNav} className="md:hidden pl-5 text-white">
          <AiOutlineMenu size={30} />
        </div>

        {/* -- Desktop -- */}
        <div className="w-full flex justify-center md:justify-between pl-8 pr-8 lg:pl-[90px] lg:pr-[80px]">
          <div className="w-[230px] h-auto">
            <Link to="/">
              <img
                src={logo}
                alt="Logo de la agencia"
                loading="lazy"
                className="w-[210px] h-[85px]"
                onClick={handleClickScroll()}
              />
            </Link>
          </div>

          <div className="hidden md:flex w-[500px] text-2xl items-center justify-end space-x-10 lg:space-x-[120px] pr-4">
            <Link to="/nosotros">
              <p
                className={`cursor-pointer text-white font-bold hover:-translate-y-1 hover:scale-110 hover:border-b-4 border-[#FFB71B] border-spacing-0 
                transition ease-in-out duration-300 ${
                  buttonSelected[1] && "border-b-4"
                }`}
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Nosotros
              </p>
            </Link>
            <Link to="/portafolio">
              <p
                className={`cursor-pointer text-white font-bold hover:-translate-y-1 hover:scale-110 hover:border-b-4 border-[#FFB71B] border-spacing-0 
                transition ease-in-out duration-300 ${
                  buttonSelected[2] && "border-b-4"
                }`}
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Portafolio
              </p>
            </Link>
            <Link to="/contacto">
              <p
                className="w-[160px] bg-[#FFB71B] font-medium px-6 py-2 rounded-lg
                 hover:bg-[#FFCC5C] hover:font-bold hover:text-[#2D2A26] active:bg-[#FCE5B2] active:text-[#6B6A69]"
                onClick={() => {
                  handleClickScroll();
                }}
              >
                Contacto
              </p>
            </Link>
          </div>
        </div>

        {/* -- Mobile -- */}

        <div
          className={
            nav
              ? "fixed left-0 top-0 w-full h-screen bg-black/70 md:hidden"
              : ""
          }
        >
          <div
            className={
              nav
                ? "md:hidden fixed left-0 top-0 w-[85%] z-[300] h-screen bg-[#F0F0F0] p-4 min-[425px]:p-6 ease-in duration-500"
                : "fixed left-[-100%] top-0 w-[85%] z-[200] h-screen bg-[#F0F0F0] p-4 ease-in duration-500"
            }
          >
            <div className="flex justify-between items-center ">
              <Link to="/" style={{ WebkitTapHighlightColor: "transparent" }}>
                <img
                  src={logo}
                  alt="Logo de la agencia"
                  loading="lazy"
                  className="w-[200px] h-[80px]"
                  onClick={() => {
                    handleNav();
                    handleClickScroll();
                  }}
                />
              </Link>
              <div
                onClick={handleNav}
                className={
                  nav
                    ? "w-10 h-10 flex justify-center items-center rounded-full shadow-md shadow-black/50"
                    : ""
                }
              >
                <AiOutlineClose className="w-6 h-6 min-[375px]:w-8 min-[375px]:h-8" />
              </div>
            </div>
            <div
              className="pt-10 pl-8 space-y-5 flex flex-col min-[425px]:text-xl"
              style={{ WebkitTapHighlightColor: "transparent" }}
            >
              <Link
                to="/"
                onClick={() => {
                  handleNav();
                  handleClickScroll();
                }}
              >
                <p
                  className={`border-b ${
                    buttonSelected[0] && "text-[#FFB71B]"
                  }`}
                >
                  Inicio
                </p>
              </Link>
              <Link
                to="/nosotros"
                onClick={() => {
                  handleNav();
                  handleClickScroll();
                }}
                style={{
                  textDecoration: "none",
                  backgroundColor: "transparent",
                }}
              >
                <p
                  className={`border-b ${
                    buttonSelected[1] && "text-[#FFB71B]"
                  }`}
                >
                  Nosotros
                </p>
              </Link>
              <Link
                to="/portafolio"
                onClick={() => {
                  handleNav();
                  handleClickScroll();
                }}
              >
                <p
                  className={`border-b ${
                    buttonSelected[2] && "text-[#FFB71B]"
                  }`}
                >
                  Portafolio
                </p>
              </Link>
              <div>
                <Link
                  to="/contacto"
                  onClick={() => {
                    handleNav();
                    handleClickScroll();
                  }}
                >
                  <button className="bg-[#FFB71B] text-black px-6 py-2 rounded-lg font-semibold">
                    Contacto
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
