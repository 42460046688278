import React from "react";
import { getRandomCards } from "../../../utils/ArrayCards.js";
import Template from "./Template.jsx";

// // img
import banner from "../../../assets/portafolio/proyecto4/E4_Portada_Interior.jpg";
import imgProject1 from "../../../assets/portafolio/proyecto4/e4-01.jpg";
import imgProject2 from "../../../assets/portafolio/proyecto4/e4-02.jpg";
import imgProject3 from "../../../assets/portafolio/proyecto4/e4-03.jpg";
import imgProject4 from "../../../assets/portafolio/proyecto4/e4-04.jpg";
import imgProject5 from "../../../assets/portafolio/proyecto4/e4-05.jpg";
import imgProject6 from "../../../assets/portafolio/proyecto4/e4-06.jpg";
import imgExtra1 from "../../../assets/portafolio/proyecto4/e4-07.jpg";
import imgExtra2 from "../../../assets/portafolio/proyecto4/e4-08.jpg";

const Proyecto4 = () => {
  const currentProjectIndex = 3;
  const randomCards = getRandomCards(3, currentProjectIndex);
  const images = [
    imgProject1,
    imgProject2,
    imgProject3,
    imgProject4,
    imgProject5,
    imgProject6,
  ];
  const title = "Éramos Cuatro colectivo";
  const info = "Naming | Identidad de marca";
  const texts = [
    "ÉramosCuatro, colectivo creativo está formado por un grupo de profesionales del diseño, la publicidad y la comunicación. Su objetivo es crear proyectos originales y atractivos que transmitan emociones y despierten la imaginación. La identidad visual del colectivo se basa en formas sencillas y colores cálidos representando la unidad y la colaboración del equipo.",
  ];
  const frase =
    " “Su identidad visual está pensada para reflejar el espíritu creativo y el entusiasmo del colectivo. Es una imagen sencilla y memorable ayudando a posicionar al colectivo como una referencia en el mundo de la creatividad”";
  const color1 = "bg-[#7a2c00]";
  const color2 = "bg-[#d1d1d1]";
  const color3 = "bg-[#b2b2b2]";
  const color4 = "bg-[#e84e0f]";
  const color5 = "bg-[#626363]";
  const color6 = "bg-[#b23f0a]";
  const color7 = "bg-[#ef8357]";
  const color8 = "bg-[#f0f0f0]";
  return (
    <Template
      banner={banner}
      images={images}
      title={title}
      info={info}
      texts={texts}
      frase={frase}
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      color5={color5}
      color6={color6}
      color7={color7}
      color8={color8}
      imgExtra1={imgExtra1}
      imgExtra2={imgExtra2}
      randomCards={randomCards}
    />
  );
};

export default Proyecto4;
