import React, { useState } from "react";
import { validateEmail, validateForm } from "../../utils/Validations.js";
import Checkbox from "react-custom-checkbox";
import { AiFillCheckSquare } from "react-icons/ai";
import axios from "axios";
import { MensajeEnviado } from "./MensajeEnviado.jsx";

const Contacto = () => {
  // Validaciones de los inputs para cambiar el estilado segun este llenos o vacios
  const [name, setName] = useState("");
  const [isNameFilled, setIsNameFilled] = useState(null);
  const [phone, setPhone] = useState("");
  const [isPhoneFilled, setIsPhoneFilled] = useState(null);
  const [email, setEmail] = useState("");
  const [isEmailFilled, setIsEmailFilled] = useState(null);
  // eslint-disable-next-line
  const [area, setArea] = useState("default");
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [text, setText] = useState("");
  const [isTextFilled, setIsTextFilled] = useState(null);
  const [clienteNuevo, setClienteNuevo] = useState(false);

  // Cambio de vista al enviar el form
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isContactVisible, setIsContactVisible] = useState(true);

  //Inputs
  const handleTextChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    if (inputName === "Nombre") {
      setName(inputValue);
      setIsNameFilled(inputValue.trim().length > 0 ? true : false);
    } else if (inputName === "Teléfono") {
      setPhone(inputValue);
      setIsPhoneFilled(inputValue.trim().length > 0 ? true : false);
    } else if (inputName === "Email") {
      setEmail(inputValue);
      setIsEmailFilled(inputValue.trim().length > 0);
    } else if (inputName === "Mensaje") {
      setText(inputValue);
      setIsTextFilled(inputValue.trim().length > 0);
    }
  };

  // Datos ingresados correctamente?
  const handleNameBlur = () => {
    if (name.trim().length === 0 || !isNaN(name.trim())) {
      setIsNameFilled(false);
    }
  };
  const handlePhoneBlur = () => {
    if (phone.trim().length === 0 || isNaN(phone.trim())) {
      setIsPhoneFilled(false);
    }
  };

  const handleEmailBlur = () => {
    const isEmailValid = validateEmail(email);
    setIsEmailFilled(isEmailValid);
  };

  const handleTextAreaBlur = () => {
    setIsTextFilled(text.trim().length > 0);
  };

  //elejir opcion del select
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setArea(selectedValue);
    setIsAreaSelected(selectedValue !== "default");
  };

  //Validar y enviar Form
  const isFormValid =
    isNameFilled && isPhoneFilled && isEmailFilled && isTextFilled;

  const handleSubmit = () => {
    const isEmailValid = validateEmail(email);
    const isFormValid = validateForm(name, email, phone, text);

    //Enviado
    if (isEmailValid && isFormValid) {
      setIsFormSubmitted(true);
      console.log("Formulario válido. Datos enviados!");
    } else {
      console.log("Formulario inválido. Verifica los campos.");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      // eslint-disable-next-line
      const response = await axios.post(
        "https://getform.io/f/2093b7c9-ceeb-4e2f-b71e-604d88454217",
        formData
      );

      setIsFormSubmitted(true);
      setIsContactVisible(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="relative w-full md:min-h-screen pt-32 flex flex-col items-center md:justify-center">
      {isFormSubmitted && !isContactVisible ? (
        <MensajeEnviado />
      ) : (
        <div className="flex flex-col items-center space-y-5 w-[91%]">
          <div className="flex flex-col items-center space-y-3 md:pb-10">
            <h1 className="text-3xl font-semibold md:text-[50px]">
              Déjanos un mensaje
            </h1>
            <p className="text-lg font-light text-[#575757] md:text-[22px]">
              Cuéntanos cómo podemos ayudarte.
            </p>
          </div>

          <form
            id="contactForm"
            onSubmit={handleFormSubmit}
            className="flex flex-col space-y-6 w-[95%] md:space-y-10 xl:w-[70%]"
          >
            {/* Name & Number */}
            <div
              className="flex flex-col space-y-6 md:space-y-10 
            lg:flex-row lg:justify-between lg:space-y-0 lg:space-x-6"
            >
              <div className="flex relative lg:w-full">
                <input
                  name="Nombre"
                  type="text"
                  placeholder="Nombre"
                  className={`w-full border rounded-md text-lg p-4 bg-[#F2F2F2] md:text-2xl
                ${
                  isNameFilled === false
                    ? "border-[#DA5928]"
                    : isNameFilled
                    ? "border-[#FFB71B]"
                    : "border-[#EEEEEE]"
                }`}
                  value={name}
                  onChange={handleTextChange}
                  onBlur={handleNameBlur}
                />
                <p
                  className={`absolute text-[#DA5928] top-[58px] left-1 text-[15px] md:top-16 ${
                    isNameFilled === false ? "" : "hidden"
                  }`}
                >
                  Por favor, verifique los datos ingresados.
                </p>
                <p className="text-3xl text-[#DA5928] absolute -right-3 -top-2">
                  *
                </p>
              </div>
              <div className="flex relative lg:w-full">
                <input
                  name="Teléfono"
                  type="text"
                  placeholder="Teléfono"
                  className={`w-full border rounded-md text-lg p-4 bg-[#F2F2F2] md:text-2xl
                ${
                  isPhoneFilled === false
                    ? "border-[#DA5928]"
                    : isNameFilled
                    ? "border-[#FFB71B]"
                    : "border-[#EEEEEE]"
                }`}
                  value={phone}
                  onChange={handleTextChange}
                  onBlur={handlePhoneBlur}
                />
                <p
                  className={`absolute text-[#DA5928] top-[58px] left-1 text-[15px] md:top-16 ${
                    isPhoneFilled === false ? "" : "hidden"
                  }`}
                >
                  Por favor, verifique los datos ingresados.
                </p>
                <p className="text-3xl text-[#DA5928] absolute -right-3 -top-2">
                  *
                </p>
              </div>
            </div>
            {/* EMAIL */}
            <div className="flex relative">
              <input
                type="email"
                name="Email"
                placeholder="Correo Electrónico"
                className={`w-full border rounded-md text-lg p-4 bg-[#F2F2F2] md:text-2xl
              ${
                isEmailFilled === false
                  ? "border-[#DA5928]"
                  : isNameFilled
                  ? "border-[#FFB71B]"
                  : "border-[#EEEEEE]"
              }`}
                value={email}
                onChange={handleTextChange}
                onBlur={handleEmailBlur}
              />
              <p
                className={`absolute text-[#DA5928] top-[58px] left-1 text-[15px] md:top-16 ${
                  isEmailFilled === false ? "" : "hidden"
                }`}
              >
                Por favor, verifique los datos ingresados.
              </p>
              <p className="text-3xl text-[#DA5928] absolute -right-3 -top-2">
                *
              </p>
            </div>

            {/* Dropdown menu */}
            <select
              name="Asunto"
              defaultValue="default"
              className={`flex flex-col w-full  border rounded-md bg-[#F2F2F2] text-xs p-4 md:text-2xl
            ${
              isAreaSelected
                ? "border-[#FFB71B] text-black"
                : "border-[#EEEEEE] text-gray-400"
            }`}
              onChange={handleSelectChange}
            >
              <option className="" value="default">
                ¿En qué área te podemos ayudar?
              </option>
              <option value="Animación">Animación</option>
              <option value="Marketing Digital">Marketing Digital</option>
              <option value="Desarrollo Web">Desarrollo Web</option>
              <option value="Diseño Gráfico">Diseño Gráfico</option>
              <option value="Diseño UX/UI">Diseño UX/UI</option>
            </select>

            {/* Text Area */}
            <div className="flex relative">
              <textarea
                name="Mensaje"
                placeholder="¿Cómo te podemos ayudar?"
                className={`w-full border rounded-md text-xl p-4 bg-[#F2F2F2] resize-none md:text-2xl
              ${
                isTextFilled === false
                  ? "border-[#DA5928]"
                  : isNameFilled
                  ? "border-[#FFB71B]"
                  : "border-[#EEEEEE]"
              }`}
                style={{ height: "250px" }}
                value={text}
                onChange={handleTextChange}
                onBlur={handleTextAreaBlur}
              />
              <p
                className={`absolute text-[#DA5928] top-[248px] left-1 text-[15px] ${
                  isTextFilled === false ? "" : "hidden"
                }`}
              >
                Por favor, verifique los datos ingresados.
              </p>
              <p className="text-3xl text-[#DA5928] absolute -right-3 -top-2">
                *
              </p>
            </div>
            {/* Checkbox */}
            <div className="flex items-center ">
              <input
                hidden
                type="hidden"
                name="ClienteNuevo?"
                value={clienteNuevo}
              />
              <Checkbox
                icon={
                  <AiFillCheckSquare
                    color="black"
                    size={44}
                    className="-m-20"
                  />
                }
                checked={clienteNuevo}
                onChange={() => setClienteNuevo(!clienteNuevo)}
                borderColor="black"
                size={30}
              />
              <p className="text-[20px] pl-2 md:text-[30px] text-[#575757]">
                Soy un cliente nuevo.
              </p>
            </div>

            {/* Button Enviar */}
            <div className="flex justify-center pt-4 pb-10 md:pt-0">
              <button
                type="submit"
                className={`w-28 h-10 rounded-md text-xl text-white bg-[#2D2A26] cursor-not-allowed
              hover:bg-[#343434] active:bg-[#636363] md:w-44 md:h-16 md:text-2xl
              ${!isFormValid ? "" : "cursor-pointer"}`}
                onClick={() => {
                  handleSubmit();
                }}
                disabled={!isFormValid}
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default Contacto;
