import img1 from "../assets/portafolio/proyecto1/dica_02.jpg";
import img2 from "../assets/portafolio/proyecto2/Naturaliz_01.jpg";
import img3 from "../assets/portafolio/proyecto3/intek_01.jpg";
import img4 from "../assets/portafolio/proyecto4/e4-04.jpg";
import img5 from "../assets/portafolio/proyecto5/Al-Ssur-01.jpg";
import img6 from "../assets/portafolio/proyecto6/Rene-06.jpg";

export const cards = [
  {
    img: img1,
    title: "Dica Interiores",
    description: " - Identidad de marca | Diseño digital",
    route: "/portafolio/proyecto-1",
  },
  {
    img: img2,
    title: "Naturaliz",
    description: " - Identidad de marca",
    route: "/portafolio/proyecto-2",
  },
  {
    img: img3,
    title: "Intek",
    description: " - Nombre | identidad de marca",
    route: "/portafolio/proyecto-3",
  },
  {
    img: img4,
    title: "Éramos Cuatro colectivo",
    description: " - Naming | Identidad de marca",
    route: "/portafolio/proyecto-4",
  },
  {
    img: img5,
    title: "AL-SSUR",
    description: " - Identidad de marca | Diseño digital",
    route: "/portafolio/proyecto-5",
  },
  {
    img: img6,
    title: "René De Magdala",
    description: " - Branding | arte para disco",
    route: "/portafolio/proyecto-6",
  },
];

export const getShuffledIndices = () => {
  return [...Array(cards.length).keys()].sort(() => Math.random() - 0.5);
};

export const getRandomCards = (count, currentProject) => {
  const shuffledIndices = getShuffledIndices();
  const filteredIndices = shuffledIndices.filter(
    (index) => index !== currentProject
  );
  return filteredIndices.slice(0, count).map((index) => cards[index]);
};
