import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Servicios from "./sectionServicios/Servicios.jsx";
import MapaMetodologia from "./sectionMetodologia/MapaMetodologia.jsx";
import TestimoniosSlider from "./sectionTestimonios/TestimoniosSlider.jsx";
// import kroomaFondo from "../assets/krooma/logoSolo.png";
import Slider from "./slider/Slider.jsx";

const Home = () => {
  AOS.init({ once: true });

  return (
    <main className="md:mb-48">
      {/* Slider Home */}
      <section>
        <Slider />
      </section>

      {/* Seccion conocer más */}
      <section className="relative w-full h-[450px] md:h-[600px] lg:h-[800px] xl:min-h-screen bg-[#FFCD5F]">
        <div
          className="relative h-full text-white flex flex-col justify-center items-center space-y-16 md:space-y-20 xl:pb-8"
          //  pl-3 md:pl-[50px] lg:pl-[100px] md:w-[80%] z-20
        >
          <div
            // data-aos="fade-right"
            data-aos-easing="ease-out-back"
            className="text-center space-y-4 xl:pt-16"
            // className="text-[50px]/[50px] font-bold drop-shadow-2xl w-[90%] z-10 sm:w-[70%] md:w-[700px] md:h-[300px] md:text-[70px]/[70px]"
          >
            <p
              className="text-[28px] font-extrabold tracking-[10px] text-black ml-2 
            min-[375px]:ml-4 min-[375px]:tracking-[14px] min-[500px]:text-[40px] md:text-[50px] 
            lg:text-[65px] lg:tracking-[45px] xl:tracking-[53px] xl:text-[85px]"
            >
              FUSIONAMOS
            </p>
            <p
              className="bg-black text-[25px] w-[99%] min-[375px]:w-full min-[500px]:text-[30px] md:text-[40px] 
            lg:text-[63px] xl:text-[83px]"
            >
              EL ARTE DEL DISEÑO
            </p>
            <p
              className="text-[18px] text-black min-[375px]:text-[22px] min-[500px]:text-[25px] md:text-[35px] 
            lg:text-[50px] xl:text-[63px]"
            >
              CON EL
              <span
                className="text-[25px] font-extrabold min-[375px]:text-[28px] min-[500px]:text-[30px] md:text-[40px] 
              lg:text-[63px] xl:text-[83px]"
              >
                {" "}
                MUNDO DIGITAL,
              </span>
            </p>
            <p
              className="bg-black text-[18px] px-2 w-[99%] min-[375px]:text-22 min-[375px]:w-full min-[500px]:text-[25px] md:text-[35px] 
            lg:text-[50px] xl:text-[63px]"
            >
              PARA{" "}
              <span
                className="text-[22px] font-extrabold min-[375px]:text-[28px] min-[500px]:text-[30px] md:text-[40px] 
              lg:text-[65px] xl:text-[85px]"
              >
                POTENCIAR
              </span>{" "}
              TU MARCA
            </p>
          </div>
          <Link to="/nosotros">
            <button
              // data-aos="fade-right"
              data-aos-easing="ease-out-back"
              data-aos-delay="200"
              className="w-52 md:w-[300px] bg-[#2D2A26] hover:bg-[#343434] active:bg-[#636363] text-xl font-semibold z-20 md:text-[35px] py-4 md:py-6 md:px-4 md:mb-10 rounded-3xl"
            >
              Conocer más
            </button>
          </Link>
        </div>
      </section>

      {/* Sección metodología */}
      <section className="w-full h-[850px] md:h-[1000px] lg:h-[900px] bg-[#F5F5F5] lg:pt-[120px]">
        <div
          data-aos="zoom-in"
          data-aos-delay="200"
          data-aos-easing="ease-out-back"
          className="w-full pt-10 pl-6 md:pl-14"
        >
          <p className="text-[20px] font-thin text-[#000000] md:text-[30px]">
            ¿Cómo lo logramos?
          </p>
          <h2 className="text-[40px] md:text-[50px] text-[#DA5928] font-semibold">
            Metodología
          </h2>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-delay="200"
          data-aos-easing="ease-out-back"
          className="w-full h-[80%] flex flex-col items-center pt-10 lg:pt-0 lg:pb-40 lg:pl-10 lg:justify-center"
        >
          <MapaMetodologia />
        </div>
      </section>

      {/* Sección Servicios */}
      <section className="">
        <Servicios />
      </section>

      {/* Seccion Testimonios */}
      <section className="w-full h-3/4 md:h-[684px]">
        <div className="w-full pt-[70px] pl-3 md:pl-14">
          <p className="text-[20px] font-thin md:text-[30px] pr-2">
            ¿Que opinan nuestros clientes?
          </p>
          <h2 className="text-[40px] md:text-[50px] text-[#DA5928] font-semibold">
            Testimonios
          </h2>
        </div>
        <div>
          <TestimoniosSlider />
        </div>
      </section>
    </main>
  );
};
export default Home;
