import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imgEnviado from "../../assets/contacto/Ilustracion_formulario.png";

export const MensajeEnviado = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      navigate("/");
    }
  }, [countdown, navigate]);

  return (
    <section className="min-h-screen pt-20 pb-20">
      <div className="flex flex-col items-center text-center space-y-4 2xl:space-y-10">
        <img
          className="w-[280px] h-[360px] mr-10 md:w-[380px] md:h-[460px] md:mr-24 2xl:w-[480px] 2xl:h-[560px]"
          src={imgEnviado}
          alt="Ilustracion de astronauta en bici"
        />
        <h1 className="text-3xl md:text-6xl lg:text-[90px]">
          Tu mensaje fue enviado
        </h1>
        <p className="md:text-lg lg:text-[35px] text-[#575757]">
          Nos ponderemos en contacto dentro de las 24hs.
        </p>
        <p className="text-[#9E9E9E] font-medium md:text-[25px]">
          Redirigiendo en {countdown}...
        </p>
      </div>
    </section>
  );
};
