import { createContext, useState } from "react";

export const Utils = createContext({});

export const UtilsProvider = ({ children }) => {
  const [buttonSelected, setButtonSelected] = useState([true, false, false]);

  const value = {
    buttonSelected,
    setButtonSelected,
  };
  return <Utils.Provider value={value}>{children}</Utils.Provider>;
};
