import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Portafolio y sus proyectos
import PortafolioMain from "../components/portafolio/PortafolioMain";
import Proyecto1 from "../components/portafolio/proyectos/Proyecto1";
import Proyecto2 from "../components/portafolio/proyectos/Proyecto2";
import Proyecto3 from "../components/portafolio/proyectos/Proyecto3";
import Proyecto4 from "../components/portafolio/proyectos/Proyecto4";
import Proyecto5 from "../components/portafolio/proyectos/Proyecto5";
import Proyecto6 from "../components/portafolio/proyectos/Proyecto6";

import Contacto from "../components/contacto/Contacto";
import Nosotros from "../components/Nosotros";
import CookiesDiv from "../components/CookiesDiv";
import PoliticasPrivacidad from "../components/PoliticasPrivacidad";
import { MensajeEnviado } from "../components/contacto/MensajeEnviado";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={children} />

        <Route path="/portafolio" element={<PortafolioMain />} />
        {/* Rutas trabajos del portafolio */}
        <Route path="/portafolio/proyecto-1" element={<Proyecto1 />} />
        <Route path="/portafolio/proyecto-2" element={<Proyecto2 />} />
        <Route path="/portafolio/proyecto-3" element={<Proyecto3 />} />
        <Route path="/portafolio/proyecto-4" element={<Proyecto4 />} />
        <Route path="/portafolio/proyecto-5" element={<Proyecto5 />} />
        <Route path="/portafolio/proyecto-6" element={<Proyecto6 />} />

        <Route path="/contacto" element={<Contacto />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/politicas-privacidad" element={<PoliticasPrivacidad />} />
        <Route path="/mensaje-enviado" element={<MensajeEnviado />} />
      </Routes>
      <CookiesDiv />
      <Footer />
    </>
  );
};

export default Layout;
