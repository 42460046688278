import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const SliderItem = ({ item }) => {
  return (
    <div
      className="w-full max-w-full max-h-full md:h-[850px] md:min-h-screen bg-cover bg-center flex items-end"
      style={{
        backgroundImage: `url(${item.img})`,
        height: "93vh",
      }}
    >
      <div className="flex flex-col items-start pl-3 pb-[60px] text-white md:pl-10 lg:pl-[100px]">
        <h2 className="font-bold text-3xl text-start pl-1 md:pl-[3px] md:pb-3 md:text-[50px]">
          {item.title}
        </h2>
        <p className="text-[16px] sm:text-xl pl-1">{item.description}</p>
        <Link to={item.route}>
          <div className="text-xl px-1 flex-row group lg:text-[25px]">
            <button
              className="flex items-center border-b-2 group-hover:font-bold
            transition ease-in-out duration-300"
            >
              {item.button}
              <BsArrowRight className="pl-2 w-8 h-8 group-hover:scale-125 transition ease-in-out duration-300" />
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SliderItem;
