import React from "react";
import ProjectCardItem from "../ProjectCardItem.jsx";

const Template = (props) => {
  const {
    banner,
    images,
    title,
    info,
    texts,
    frase,
    imgExtra1,
    imgExtra2,
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    randomCards,
  } = props;

  return (
    <section className="w-full min-h-screen overflow-x-hidden bg-[#2D2A26] pt-[100px] z-0">
      <div className="w-full lg:max-h-[600px]">
        <img
          src={banner}
          alt="/"
          loading="lazy"
          className="object-cover xl:object-bottom w-full h-[300px] md:h-auto max-h-[600px] "
        />
      </div>
      <div className="w-full min-h-0 bg-[#2D2A26] p-3 text-white sm:text-lg md:text-xl">
        <div className="md:w-[98%] space-y-5 md:pl-10 md:pb-[160px] lg:w-[66%] lg:pl-[100px]">
          <div className="md:pt-[100px] md:pb-[70px] md:space-y-6">
            <h2 className="text-[#FFB71B] font-medium text-[25px] md:text-[40px]">
              {title}
            </h2>
            <h2 className="md:text-[30px]">{info}</h2>
          </div>
          {texts.map((text, index) => (
            <p
              className="lg:text-[25px] md:leading-9 text-justify pr-2 pb-2"
              key={index}
            >
              {text}
            </p>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="imagen muestra del proyecto"
            loading="lazy"
            className="block m-0 w-full h-auto object-cover"
          />
        ))}
      </div>
      <div className="w-full min-h-0 md:h-[350px] lg:h-[450px] bg-[#2D2A26] text-white text-center flex justify-center items-center relative">
        <div className="absolute z-20 hidden md:flex top-6 left-10 lg:top-12 2xl:left-32">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color1} drop-shadow-lg`}
          />
        </div>
        <div className="absolute z-20 hidden md:flex top-6 left-24 lg:top-12 lg:left-28 2xl:left-48">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color2} drop-shadow-lg`}
          />
        </div>

        <div className="absolute z-20 hidden md:flex top-6 right-24 lg:top-12 lg:right-28 2xl:right-48">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color3} drop-shadow-lg`}
          />
        </div>
        <div className="absolute z-20 hidden md:flex top-6 right-10 lg:top-12 2xl:right-32">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color4} drop-shadow-lg`}
          />
        </div>

        <p className="md:w-[70%] min-h-0 text-[15px] py-12 px-5 md:text-xl md:px-16 md:py-24 lg:leading-9 2xl:text-[30px]">
          {frase}
        </p>

        <div className="absolute z-20 hidden md:flex top-56 left-10 lg:top-72 2xl:left-32">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color5} drop-shadow-lg`}
          />
        </div>
        <div className="absolute z-20 hidden md:flex top-56 left-24 lg:top-72 lg:left-28 2xl:left-48">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color6} drop-shadow-lg`}
          />
        </div>

        <div className="absolute z-20 hidden md:flex top-56 right-24 lg:top-72 lg:right-28 2xl:right-48">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color7} drop-shadow-lg`}
          />
        </div>
        <div className="absolute z-20 hidden md:flex top-56 right-10 lg:top-72 2xl:right-32">
          <div
            className={`w-24 h-24 lg:w-32 lg:h-32 rounded-full ${color8} drop-shadow-lg`}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <img
          src={imgExtra1}
          alt="imagen muestra del proyecto"
          loading="lazy"
          className="object-contain w-full max-h-[860px]"
        />
      </div>
      <div className="w-full flex justify-center">
        <img
          src={imgExtra2}
          alt="imagen muestra del proyecto"
          loading="lazy"
          className="object-contain w-full max-h-[860px] lg:object-bottom"
        />
      </div>
      <div
        className="bg-[#2D2A26] md:pb-[80px] md:pl-10 lg:pb-[110px]
      lg:px-[40px] 2xl:px-[90px]"
      >
        <p className="text-[#FFB71B] font-semibold text-[20px] py-8 pl-3 min-[425px]:text-[27px] md:py-[40px] md:pl-0 lg:text-[40px]">
          También te puede interesar
        </p>
        <div className="flex flex-col items-center space-y-4 border border-[#2D2A26] lg:items-baseline lg:flex-row lg:space-y-0 lg:space-x-8 2xl:space-x-12 pb-8 md:pb-0">
          {randomCards.map((item) => (
            <div key={item.route}>
              <ProjectCardItem card={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Template;
