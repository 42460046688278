import React from "react";
import { getRandomCards } from "../../../utils/ArrayCards.js";
import Template from "./Template.jsx";

// img
import banner from "../../../assets/portafolio/proyecto5/AL-SSUR-Portada_Interior.jpg";
import imgProject1 from "../../../assets/portafolio/proyecto5/Al-Ssur-01.jpg";
import imgProject2 from "../../../assets/portafolio/proyecto5/Al-Ssur-02.jpg";
import imgProject3 from "../../../assets/portafolio/proyecto5/Al-Ssur-03.jpg";
import imgProject4 from "../../../assets/portafolio/proyecto5/Al-Ssur-04.jpg";
import imgProject5 from "../../../assets/portafolio/proyecto5/Al-Ssur-05.jpg";
import imgProject6 from "../../../assets/portafolio/proyecto5/Al-Ssur-06.jpg";
import imgExtra1 from "../../../assets/portafolio/proyecto5/Al-Ssur-07.jpg";
import imgExtra2 from "../../../assets/portafolio/proyecto5/Al-Ssur-08.jpg";

const Proyecto5 = () => {
  const currentProjectIndex = 4;
  const randomCards = getRandomCards(3, currentProjectIndex);
  const images = [
    imgProject1,
    imgProject2,
    imgProject3,
    imgProject4,
    imgProject5,
    imgProject6,
  ];
  const title = "AL-SSUR";
  const info = "Identidad de marca | Diseño digital";
  const texts = [
    "AL-SSUR es una empresa de arquitectura e ingeniería que ofrece un servicio integral a sus clientes, desde el diseño inicial hasta la construcción y la puesta en marcha. Con un equipo de profesionales altamente calificados, AL-SSUR se compromete a ofrecer soluciones innovadoras y de alta calidad que satisfagan las necesidades de sus clientes.",
    "AL-SSUR es corto, memorable, y sugiere la totalidad y la excelencia. Su logotipo, compuesto por líneas, representa la dirección y el progreso. Sus colores transmiten profesionalidad y confianza, su tipografía clara ayuda a crear una imagen moderna.",
  ];
  const frase =
    "“La nueva identidad de AL-SSUR está diseñada para inspirar confianza en sus clientes. El nombre, el logotipo, los colores y el tono de voz de la marca transmiten profesionalismo, calidad y compromiso”";
  const color1 = "bg-[#5d0f09]";
  const color2 = "bg-[#515353]";
  const color3 = "bg-[#990b1d]";
  const color4 = "bg-[#9b9c9e]";
  const color5 = "bg-[#e1e1e2]";
  const color6 = "bg-[#c26d77]";
  const color7 = "bg-[#787a7b]";
  const color8 = "bg-[#7c1516]";
  return (
    <Template
      banner={banner}
      images={images}
      title={title}
      info={info}
      texts={texts}
      frase={frase}
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      color5={color5}
      color6={color6}
      color7={color7}
      color8={color8}
      imgExtra1={imgExtra1}
      imgExtra2={imgExtra2}
      randomCards={randomCards}
    />
  );
};

export default Proyecto5;
