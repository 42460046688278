import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const MapaMetodologia = () => {
  AOS.init();
  return (
    <div>
      <section className="h-auto w-[970px] hidden min-[1460px]:w-[1440px] lg:grid grid-rows-2 grid-cols-10 gap-28 bg-[#F5F5F5] relative min-[1070px]:pl-6">
        {/* 1 */}

        <div className="relative w-[315px] h-[150px] flex space-x-10 items-center z-50 bg-[#F5F5F5] rounded-br-full mt-[1px]">
          <h2
            data-aos="color-change-bg-desk"
            className="text-[80px] text-[#FFB71B] font-bold rounded-full relative z-10"
          >
            1
          </h2>
          <p className="text-[30px] font-bold text-[#959292] leading-tight truncate line-clamp-3">
            Investigación
            <br /> y conocimiento
            <br />
            del cliente
          </p>
          <div
            data-aos="color-change-circle"
            data-aos-delay="800"
            className="w-[85px] h-[80px] rounded-full absolute bg-[#F5F5F5] -left-[68px] z-0 aos-animate"
          />
        </div>
        <div
          data-aos="color-change-1"
          data-aos-delay="1400"
          className="w-[300px] h-[360px] absolute rounded-l-full rounded-b-full 
        border-8 border-[#FFB71B] border-dashed border-t-transparent border-r-transparent left-2 bottom-12 min-[1070px]:bottom-14 min-[1070px]:left-9 min-[1460px]:w-[400px]"
        ></div>
        <div className="absolute w-8 h-32 left-80 top-40 bg-[#F5F5F5]" />

        {/* 2 */}
        <div className="relative w-[300px] h-[110px] flex space-x-7 items-center z-50 bg-[#F5F5F5] row-start-2 col-start-3 rounded-tr-full">
          <h2
            data-aos="color-change-bg-desk"
            data-aos-delay="1400"
            className="text-[80px] text-[#FFB71B] font-bold rounded-full pl-2 pb-4 relative z-20"
          >
            2
          </h2>
          <p
            className="text-[30px] font-bold text-[#959292] bg-[#F5F5F5] w-[190px] h-[160px] rounded-tr-[30px]
          min-[1460px]:h-[290px] min-[1460px]:flex min-[1460px]:items-center leading-tight truncate line-clamp-3 relative z-0"
          >
            Definición
            <br /> de objetivos
            <br />y estrategia
          </p>
          <div
            data-aos="color-change-circle"
            data-aos-delay="1400"
            className="w-[85px] h-[80px] rounded-full absolute bg-[#F5F5F5] -left-10 top-2 z-10"
          />
        </div>
        <div
          data-aos="color-change-2"
          data-aos-delay="2000"
          className="w-[240px] h-[310px] absolute rounded-l-full rounded-b-full 
        border-8 border-[#FFB71B] border-dashed border-b-transparent border-r-transparent left-[275px] top-[77px]
        min-[1460px]:left-[355px] min-[1460px]:w-[290px] z-10"
        ></div>

        {/* 3 */}

        <div
          className="relative w-[200px] h-[100px] flex space-x-7 items-center z-50 mt-6 ml-12 bg-[#F5F5F5] row-start-1 col-start-4 rounded-br-full
        min-[1460px]:col-start-5 min-[1460px]:ml-0"
        >
          <h2
            data-aos="color-change-bg-desk"
            data-aos-delay="2000"
            className="text-[80px] text-[#FFB71B] font-bold rounded-full pl-2 relative z-10"
          >
            3
          </h2>
          <p className="text-[30px] font-bold text-[#959292] leading-tight">
            Desarrollo creativo
          </p>
          <div
            data-aos="color-change-circle"
            data-aos-delay="2000"
            className="w-[85px] h-[80px] rounded-full absolute bg-transparent -left-10 z-0"
          />
        </div>
        <div
          data-aos="color-change-3"
          data-aos-delay="2600"
          className="w-[424px] h-[220px] absolute rounded-bl-full 
        border-8 border-[#FFB71B] border-dashed border-t-transparent border-r-transparent left-[435px] top-20 min-[1460px]:left-[660px]
        min-[1460px]:top-[94px] z-20"
        ></div>

        {/* 4 */}

        <div className="relative w-[300px] h-[100px] flex space-x-7 items-center z-50 bg-[#F5F5F5] row-start-2 col-start-6 min-[1460px]:col-start-7">
          <h2
            data-aos="color-change-bg-desk"
            data-aos-delay="2600"
            className="text-[80px] text-[#FFB71B] font-bold rounded-full pl-2 relative z-10"
          >
            4
          </h2>
          <p className="text-[30px] font-bold text-[#959292] leading-tight">
            Implementación y ejecución
          </p>
          <div
            data-aos="color-change-circle"
            data-aos-delay="2600"
            className="w-[85px] h-[80px] rounded-full absolute bg-transparent -left-8 z-0"
          />
        </div>
        <div
          data-aos="color-change-2"
          data-aos-delay="3000"
          className="w-[200px] h-[280px] col-start-1 absolute rounded-l-full rounded-b-full 
        border-8 border-[#FFB71B] border-dashed border-b-transparent border-r-transparent left-[590px] min-[1460px]:left-[946px] top-[66px] z-10"
        ></div>

        {/* 5 */}

        <div className="relative w-[303px] h-[150px] flex space-x-7 items-center z-50 bg-[#F5F5F5] row-start-1 col-start-7 min-[1460px]:col-start-8">
          <h2
            data-aos="color-change-bg-desk"
            data-aos-delay="3000"
            className="text-[80px] text-[#FFB71B] font-bold rounded-full pl-2 relative z-10"
          >
            5
          </h2>
          <p className="text-[30px] font-bold text-[#959292] leading-tight truncate line-clamp-3">
            Evaluación
            <br /> y análisis
            <br /> de resultados
          </p>
          <div
            data-aos="color-change-circle"
            data-aos-delay="3000"
            className="w-[85px] h-[80px] rounded-full absolute bg-transparent -left-9 z-0"
          />
        </div>
      </section>

      {/* Mobile */}
      <section className="lg:hidden flex flex-col space-y-14 md:space-y-20 relative">
        {/* 1 */}

        <div className="w-[280px] space-x-2 flex md:w-[300px] -mb-2">
          <h2
            data-aos="color-change-bg"
            className="w-12 h-12 md:w-24 md:h-[70px] md:text-5xl z-10 flex justify-center items-center rounded-full bg-white p-5 text-3xl font-bold text-[#FFB71B]"
          >
            1
          </h2>
          <p className="text-[20px] font-bold text-[#959292]">
            Investigación y conocimiento del cliente
          </p>
        </div>
        <div
          data-aos="color-change-line"
          data-aos-delay="800"
          className="absolute z-0 h-28 w-0 border-2 border-[#FFB71B] border-dashed left-[22px] bottom-[440px]
        md:border-4 md:h-36 md:left-[33px] md:bottom-[546px]"
        ></div>
        {/* 2 */}

        <div className="w-[280px] space-x-2 flex md:w-[300px]">
          <h2
            data-aos="color-change-bg"
            data-aos-delay="800"
            className="w-12 h-12 md:w-[90px] md:h-[70px] md:text-5xl z-10 flex justify-center items-center rounded-full bg-white p-5 text-3xl font-bold text-[#FFB71B]"
          >
            2
          </h2>
          <p className="text-[20px] font-bold text-[#959292]">
            Definición de objetivos y estrategia
          </p>
        </div>
        <div
          data-aos="color-change-line"
          data-aos-delay="1400"
          className="absolute z-0 h-24 w-0 border-2 border-[#FFB71B] border-dashed left-[22px] top-[134px]
        md:border-4 md:h-36 md:left-[33px] md:top-28"
        ></div>
        {/* 3 */}

        <div className="w-[250px] space-x-2 flex items-center md:w-[300px]">
          <h2
            data-aos="color-change-bg"
            data-aos-delay="1400"
            className="w-12 h-12 md:w-[71px] md:h-[70px] md:text-5xl z-10 flex justify-center items-center rounded-full bg-white p-5 text-3xl font-bold text-[#FFB71B]"
          >
            3
          </h2>
          <p className="text-[20px] font-bold text-[#959292]">
            Desarrollo creativo
          </p>
        </div>
        <div
          data-aos="color-change-line"
          data-aos-delay="2000"
          className="absolute z-0 h-20 w-0 border-2 border-[#FFB71B] border-dashed left-[22px] top-[283px]
        md:border-4 md:h-36 md:left-[33px] md:top-[260px]"
        ></div>
        {/* 4 */}

        <div className="w-[250px] space-x-2 flex items-center md:w-[300px]">
          <h2
            data-aos="color-change-bg"
            data-aos-delay="2000"
            className="w-12 h-12 md:w-[75px] md:h-[70px] md:text-5xl z-10 flex justify-center items-center rounded-full bg-white p-5 text-3xl font-bold text-[#FFB71B]"
          >
            4
          </h2>
          <p className="text-[20px] font-bold text-[#959292]">
            Implementación y ejecución
          </p>
        </div>
        <div
          data-aos="color-change-line"
          data-aos-delay="2600"
          className="absolute z-0 h-20 w-0 border-2 border-[#FFB71B] border-dashed left-[22px] bottom-16
         md:border-4 md:h-36 md:left-[33px]"
        ></div>
        {/* 5 */}

        <div className="w-[280px] space-x-2 flex md:w-[300px]">
          <h2
            data-aos="color-change-bg"
            data-aos-delay="2600"
            className="w-12 h-12 md:w-[85px] md:h-[70px] md:text-5xl z-10 flex justify-center items-center rounded-full bg-white p-5 text-3xl font-bold text-[#FFB71B]"
          >
            5
          </h2>
          <p className="text-[20px] font-bold text-[#959292]">
            Evaluación y análisis de resultados
          </p>
        </div>
      </section>
    </div>
  );
};

export default MapaMetodologia;
