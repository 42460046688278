import React from "react";
import ProjectCardItem from "./ProjectCardItem";
import { cards } from "../../utils/ArrayCards.js";

const PortafolioMain = () => {
  const handleCardClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="w-full flex flex-col justify-center items-center pt-28">
      <div
        className="w-full h-full space-y-7 flex flex-col items-center pb-4 pt-5 md:pt-[40px] lg:pt-[70px]
        lg:pl-[74px] lg:pr-[40px]"
      >
        <p className="text-center text-[#575757] px-4 md:text-xl md:text-left md:pb-[20px] lg:pb-[50px] md:px-12 lg:pl-[74px] lg:pr-[40px]">
          Nos apasiona la innovación y la excelencia creativa. Nos esforzamos
          por crear experiencias digitales únicas y efectivas que impulsen el
          crecimiento de nuestros clientes. Trabajamos en estrecha colaboración
          con ellos para comprender sus necesidades y ofrecer soluciones
          estratégicas que generen resultados tangibles.
        </p>
        <div className="w-full h-[1px] border" />
        <div
          className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-3 justify-center items-center pb-4 m-4
        md:pt-[20px] lg:pt-[50px]"
        >
          {cards.map((item) => (
            <ProjectCardItem
              card={item}
              key={item.route}
              onClick={handleCardClick}
            />
          ))}
        </div>
        {/* <button className="h-4 flex items-center text-white bg-[#2D2A26] hover:bg-[#343434] active:bg-[#636363] text-2xl py-6 px-10 rounded-md">
          Ver más
        </button> */}
      </div>
    </section>
  );
};
export default PortafolioMain;
