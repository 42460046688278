import React, { useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SliderItem from "./SliderItem.jsx";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Slider = () => {
  const items = [
    {
      img: require("../../assets/sliderHome/Nueva_Portada_dica.jpg"),
      title: "Dica Interiores",
      description: "Identidad de marca | Diseño digital",
      button: "Conocer más",
      route: "/portafolio/proyecto-1",
    },
    {
      img: require("../../assets/sliderHome/Nueva_Portada_Naturaliz.jpg"),
      title: "Naturaliz",
      description: "Identidad de marca",
      button: "Conocer más",
      route: "/portafolio/proyecto-2",
    },
    {
      img: require("../../assets/sliderHome/portada_intek.jpg"),
      title: "Intek",
      description: "Nombre / identidad de marca",
      button: "Conocer más",
      route: "/portafolio/proyecto-3",
    },
  ];

  const sliderRef = useRef(null);

  const handleTouchStart = (e) => {
    sliderRef.current.startX = e.touches[0].clientX;
    sliderRef.current.startY = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    const deltaX = e.touches[0].clientX - sliderRef.current.startX;
    const deltaY = e.touches[0].clientY - sliderRef.current.startY;
    let scrollDistance = 0;

    if (window.innerWidth <= 450) {
      scrollDistance = 500;
    } else {
      scrollDistance = 1000;
    }

    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      // Deslizamiento vertical
      if (deltaY < 0) {
        // Deslizamiento hacia abaja
        window.scrollTo({
          top: window.pageYOffset + scrollDistance,
          behavior: "smooth",
        });
      }
    } else {
      // Deslizamiento horizontal
      e.stopPropagation();
    }
  };

  return (
    <section
      className="lg:min-h-screen flex items-center justify-center"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className="max-w-[100%]" ref={sliderRef}>
        <Carousel
          className=""
          showStatus={false}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          stopOnHover={true}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "absolute" : "hidden"
                } top-0 bottom-0 left-0 hidden h-[480px] lg:flex justify-center items-center p-8 opacity-30 hover:opacity-100 cursor-pointer z-20 mt-40`}
                onClick={clickHandler}
              >
                <FaChevronLeft className="w-20 h-20 text-white" />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "absolute" : "hidden"
                } top-0 bottom-0 right-0 hidden h-[480px] lg:flex justify-center items-center p-8 opacity-30 hover:opacity-100 cursor-pointer z-20 mt-40`}
                onClick={clickHandler}
              >
                <FaChevronRight className="w-20 h-20 text-white" />
              </div>
            );
          }}
        >
          {items.map((item, index) => (
            <SliderItem item={item} key={index} />
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Slider;
