import React from "react";
import comillasAbiertas from "../../assets/testimonios/comillasAbiertas.png";
import comillasCerradas from "../../assets/testimonios/comillasCerradas.png";

const TestimoniosItem = ({ item }) => {
  return (
    <div className="w-full h-[680px] bg-[#FFFFFF] relative">
      <div className="w-full h-full flex flex-col justify-center items-center md:space-y-4 md:pt-4">
        <div className="w-[90%] md:w-auto h-[150px] flex justify-center ">
          <div className="flex items-center">
            <img
              src={item.img}
              alt="Logo de empresa cliente"
              loading="lazy"
              className="w-[250px]  h-auto md:w-auto"
            />
          </div>
        </div>
        <div className="w-[75%] md:w-[60%] text-xl font-light pb-28 min-[360px]:pb-32 md:pb-12 min-[520px]:text-2xl md:text-3xl text-center">
          <p>{item.text}</p>
        </div>
        <div className="w-full flex justify-between px-2 md:px-10 xl:px-32 absolute">
          <div>
            <img
              src={comillasAbiertas}
              alt=""
              loading="lazy"
              className="w-6 h-6 md:w-16 md:h-16"
            />
          </div>
          <div>
            <img
              src={comillasCerradas}
              alt=""
              loading="lazy"
              className="w-6 h-6 md:w-16 md:h-16"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimoniosItem;
