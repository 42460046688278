import React from "react";

const PoliticasPrivacidad = () => {
  return (
    <div className="w-full min-h-screen pt-32 px-8 pb-6 text-[#666] md:px-[50px]">
      <div className="min-h-0 flex flex-col justify-center space-y-8 text-left sm:text-justify">
        <div className="space-y-2">
          <h2 className="text-lg font-bold">Políticas de Privacidad</h2>
          <p>
            En Krooma, nos comprometemos a proteger la privacidad de nuestros
            clientes y a mantener la confidencialidad de la información que nos
            proporcionen. Esta política de privacidad describe cómo recopilamos,
            utilizamos y protegemos los datos personales que obtenemos a través
            de nuestros formularios de contacto. Recopilación de información
            personal Cuando completas nuestro formulario de contacto, te
            solicitamos ciertos datos personales, que pueden incluir tu nombre,
            dirección de correo electrónico, número de teléfono y cualquier otra
            información relevante para la consulta o el servicio requerido.
            Estos datos se recopilan con el propósito de brindarte una respuesta
            adecuada y ofrecerte nuestros servicios.
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-lg font-bold">
            Uso de la información recopilada
          </h2>
          <p>
            Utilizamos la información recopilada a través del formulario de
            contacto exclusivamente para los siguientes fines:
          </p>
          <ol className="space-y-4">
            <li>
              1. Comunicación: Utilizamos tu información de contacto para
              responder a tus consultas, proporcionarte la información
              solicitada y establecer una comunicación efectiva entre nuestra
              agencia y tú.
            </li>
            <li>
              2. Prestación de servicios: Si decides contratar nuestros
              servicios, utilizaremos tus datos personales para llevar a cabo
              las actividades necesarias relacionadas con la prestación de
              dichos servicios.
            </li>
            <li>
              3. Mejora de nuestros servicios: Podemos utilizar información
              agregada y anónima derivada de los datos proporcionados para
              analizar y mejorar nuestros servicios, así como para realizar
              estudios estadísticos.
            </li>
          </ol>
        </div>
        <div className="space-y-2">
          <h2 className="text-lg font-bold text-left">
            Protección de la información personal
          </h2>
          <p>
            Nos comprometemos a mantener la confidencialidad y seguridad de tu
            información personal. Implementamos medidas técnicas y organizativas
            apropiadas para proteger tus datos contra el acceso no autorizado,
            la divulgación o la alteración. Solo permitimos el acceso a tu
            información a aquellos empleados o terceros que necesiten utilizarla
            para brindarte servicios o responder a tus consultas. Compartir
            información personal No compartiremos tu información personal con
            terceros sin tu consentimiento expreso, a menos que estemos
            legalmente obligados a hacerlo. Derechos del titular de los datos
            Tienes derecho a acceder, corregir o eliminar tus datos personales
            en cualquier momento. Si deseas ejercer alguno de estos derechos o
            tienes alguna pregunta sobre nuestra política de privacidad, no
            dudes en comunicarte con nosotros a través de los canales de
            contacto proporcionados en este sitio web.
          </p>
        </div>
        <div className="space-y-2">
          <h2 className="text-lg font-bold text-left">
            Modificaciones a la política de privacidad
          </h2>
          <p>
            Nos reservamos el derecho de modificar esta política de privacidad
            en cualquier momento. Cualquier cambio será efectivo inmediatamente
            después de su publicación en nuestro sitio web. Te recomendamos
            revisar periódicamente esta política de privacidad para estar
            informado sobre cómo protegemos tu información personal. Espero que
            este ejemplo de política de privacidad sea útil para tu agencia
            creativa. Recuerda adaptarlo y personalizarlo según las necesidades
            y regulaciones específicas de tu país y tu empresa. Si tienes más
            preguntas, no dudes en hacerlas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PoliticasPrivacidad;
