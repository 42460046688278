import React from "react";
import { getRandomCards } from "../../../utils/ArrayCards.js";
import Template from "./Template.jsx";

// img
import banner from "../../../assets/portafolio/proyecto3/Nueva_Portada_Interior_Intek.gif";
import imgProject1 from "../../../assets/portafolio/proyecto3/intek_01.jpg";
import imgProject2 from "../../../assets/portafolio/proyecto3/intek_02.jpg";
import imgProject3 from "../../../assets/portafolio/proyecto3/intek_03.jpg";
import imgProject4 from "../../../assets/portafolio/proyecto3/intek_04.jpg";
import imgProject5 from "../../../assets/portafolio/proyecto3/intek_05.jpg";
import imgProject6 from "../../../assets/portafolio/proyecto3/intek_06.jpg";
import imgExtra1 from "../../../assets/portafolio/proyecto3/intek_07.jpg";
import imgExtra2 from "../../../assets/portafolio/proyecto3/intek_08.jpg";

const Proyecto3 = () => {
  const currentProjectIndex = 2;
  const randomCards = getRandomCards(3, currentProjectIndex);

  const images = [
    imgProject1,
    imgProject2,
    imgProject3,
    imgProject4,
    imgProject5,
    imgProject6,
  ];

  const title = "Intek";
  const info = "Nombre | identidad de marca";

  const texts = [
    '"Intek" es una combinación de las palabras "inteligente" y "tecnología". Es un nombre breve y fácil de recordar que transmite el enfoque de la empresa en brindar soluciones tecnológicas innovadoras y de vanguardia.',
    'Su diseño simple incorpora una "i" estilizada que representa innovación y una "t" que representa tecnología, la combinación de estos dos elementos ayuda a crear una fuerte identidad combinada con sus valores cromáticos que se asocian con la confianza.',
  ];

  const frase =
    "“Se inspira en la naturaleza y en la belleza de los materiales para crear ambientes que transmitan tranquilidad y serenidad”";

  const color1 = "bg-[#191533]";
  const color2 = "bg-[#8B3E17]";
  const color3 = "bg-[#EB916C]";
  const color4 = "bg-[#5C5C85]";
  const color5 = "bg-[#E3622D]";
  const color6 = "bg-[#333366]";
  const color7 = "bg-[#292853]";
  const color8 = "bg-[#AF4D21]";

  return (
    <Template
      banner={banner}
      images={images}
      title={title}
      info={info}
      texts={texts}
      frase={frase}
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      color5={color5}
      color6={color6}
      color7={color7}
      color8={color8}
      imgExtra1={imgExtra1}
      imgExtra2={imgExtra2}
      randomCards={randomCards}
    />
  );
};

export default Proyecto3;
