import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Pagination, Autoplay, EffectFade } from "swiper";
import TestimoniosItem from "./TestimoniosItem.jsx";

const TestimoniosSlider = () => {
  const items = [
    {
      img: require("../../assets/testimonios/testimonio1.png"),
      text: "Desde el primer contacto sentimos una conexión agradable y fluida, es un equipo calificado y experimentado, crean soluciones visuales impresionantes que capturan perfectamente la esencia de nuestra marca. Son capaces de comunicar de manera efectiva y conectar con el público objetivo.",
    },
    {
      img: require("../../assets/testimonios/testimonio2.png"),
      text: "Hemos trabajado con Krooma durante los últimos meses y estamos impresionados con los resultados obtenidos y la capacidad para crear soluciones visuales funcionales. Siempre están dispuestos a hacer un esfuerzo adicional para satisfacer los objetivos de la marca.",
    },
    {
      img: require("../../assets/testimonios/testimonio3.png"),
      text: "Estamos encantados al decir que somos clientes felices de Krooma. Ha sido increíble trabajar con ellos, nos han ayudado a crear una identidad visualmente funcional y atractiva, conectando los valores que reflejamos a nuestros clientes. Recomendamos a krooma a quien esté buscando una agencia de comunicación visual.",
    },
  ];

  return (
    <section>
      <Swiper
        className="custom-swiper"
        grabCursor={true}
        loop={true}
        effect={"fade"}
        speed={500}
        pagination={{ clickable: true }}
        autoplay={{ delay: 10000 }}
        modules={[Pagination, Autoplay, EffectFade]}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <TestimoniosItem item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default TestimoniosSlider;
