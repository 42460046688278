import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="relative w-full h-auto bg-[#FFB71B] flex flex-col items-center p-4 space-y-4 md:flex-row md:items-center md:justify-between md:px-12 z-10">
      {/* left */}
      <div className="flex flex-col space-y-3">
        <div className="flex flex-col md:flex-row space-x-1">
          <p className="text-center min-[425px]:text-base">
            © 2023 Krooma Studio.
          </p>
          <p className="text-center min-[425px]:text-base">
            Todos los derechos reservados.
          </p>
        </div>
        <Link to="/politicas-privacidad">
          <div className="flex justify-center">
            <h3
              className="font-semibold text-base transition ease-in-out duration-300 cursor-pointer
            hover:-translate-y-1 hover:underline active:text-[#7D7D7D] min-[1100px]:text-[20px]"
            >
              POLÍTICA DE PRIVACIDAD
            </h3>
          </div>
        </Link>
      </div>

      {/* right */}
      <div className="flex flex-col space-y-2">
        <p className="text-md lg:text-xl">hola@krooma.com</p>
        <div className="flex items-center justify-center space-x-4 md:pb-1 ">
          <div>
            <a
              href="https://www.instagram.com/kroomastudio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillInstagram className="w-9 h-9 lg:w-[54px] lg:h-[54px]" />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/kroomastudio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="w-[30px] h-[30px] lg:w-12 lg:h-12" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

/* <div className="space-y-2 sm:w-[140px] lg:w-[200px] xl:w-[140px]">
<h3
className="font-bold lg:text-xl transition ease-in-out duration-300 
hover:-translate-y-1"
>
SERVICIOS
</h3>
<p
className="text-sm lg:text-lg hover:underline 
transition ease-in-out duration-300 hover:-translate-y-1"
>
Branding
</p>
<p
className="text-sm lg:text-lg hover:underline 
transition ease-in-out duration-300 hover:-translate-y-1"
>
Desarrollo web
</p>
<p
className="text-sm lg:text-lg hover:underline 
transition ease-in-out duration-300 hover:-translate-y-1"
>
Diseño Gráfico
</p>
<p
className="text-sm lg:text-lg hover:underline 
transition ease-in-out duration-300 hover:-translate-y-1"
>
Metodología
</p>
</div> */

/* <div className="lg:space-x-6">
<h3
className="font-bold transition ease-in-out duration-300 
hover:-translate-y-1 sm:pr-8 md:w-[160px] lg:w-[200px] lg:text-xl xl:w-[210px]"
>
SOBRE NOSOTROS
</h3>
</div> */

/* <h3 className="font-bold lg:text-xl">CONTACTO</h3> */
