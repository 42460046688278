import React from "react";
import { getRandomCards } from "../../../utils/ArrayCards.js";
import Template from "./Template.jsx";

// img
import banner from "../../../assets/portafolio/proyecto1/Nueva_Portada_Interior_dica.gif";
import imgProject1 from "../../../assets/portafolio/proyecto1/dica_01.jpg";
import imgProject2 from "../../../assets/portafolio/proyecto1/dica_02.jpg";
import imgProject3 from "../../../assets/portafolio/proyecto1/dica_03.jpg";
import imgProject4 from "../../../assets/portafolio/proyecto1/dica_04.jpg";
import imgProject5 from "../../../assets/portafolio/proyecto1/dica_05.jpg";
import imgProject6 from "../../../assets/portafolio/proyecto1/dica_06.jpg";
import imgExtra1 from "../../../assets/portafolio/proyecto1/dica_07.jpg";
import imgExtra2 from "../../../assets/portafolio/proyecto1/dica_08.jpg";

const Proyecto1 = () => {
  const currentProjectIndex = 0;
  const randomCards = getRandomCards(3, currentProjectIndex);

  const images = [
    imgProject1,
    imgProject2,
    imgProject3,
    imgProject4,
    imgProject5,
    imgProject6,
  ];

  const title = "Dica interiores";
  const info = "Identidad de marca | Diseño digital";

  const texts = [
    "Dica interiores se caracteriza por un diseño simple y natural que busca crear espacios armónicos y acogedores. Su enfoque minimalista permite resaltar la belleza de los detalles y de los materiales, creando una atmósfera elegante y sofisticada. Sus colores neutros y naturales crean una sensación de calidez y armonía basados en la funcionalidad, la belleza y la sostenibilidad.",
    "En definitiva, Dica se basa en la simplicidad y en la naturalidad, creando un espacio que brinda un ambiente de paz y tranquilidad.",
  ];

  const frase =
    "“Se inspira en la naturaleza y en la belleza de los materiales para crear ambientes que transmitan tranquilidad y serenidad”";

  const color1 = "bg-[#3D5E16]";
  const color2 = "bg-[#CDCDCE]";
  const color3 = "bg-[#4E4E50]";
  const color4 = "bg-[#618D25]";
  const color5 = "bg-[#59595B]";
  const color6 = "bg-[#76AB2C]";
  const color7 = "bg-[#ABCD80]";
  const color8 = "bg-[#9b9b9d]";

  return (
    <Template
      banner={banner}
      images={images}
      title={title}
      info={info}
      texts={texts}
      frase={frase}
      imgExtra1={imgExtra1}
      imgExtra2={imgExtra2}
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      color5={color5}
      color6={color6}
      color7={color7}
      color8={color8}
      randomCards={randomCards}
    />
  );
};

export default Proyecto1;
