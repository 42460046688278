import React from "react";
import { getRandomCards } from "../../../utils/ArrayCards.js";
import Template from "./Template.jsx";

// img
import banner from "../../../assets/portafolio/proyecto6/Rene_Portada_Interior.jpg";
import imgProject1 from "../../../assets/portafolio/proyecto6/Rene-01.jpg";
import imgProject2 from "../../../assets/portafolio/proyecto6/Rene-02.jpg";
import imgProject3 from "../../../assets/portafolio/proyecto6/Rene-03.jpg";
import imgProject4 from "../../../assets/portafolio/proyecto6/Rene-04.jpg";
import imgProject5 from "../../../assets/portafolio/proyecto6/Rene-05.jpg";
import imgProject6 from "../../../assets/portafolio/proyecto6/Rene-06.jpg";
import imgExtra1 from "../../../assets/portafolio/proyecto6/Rene-07.jpg";
import imgExtra2 from "../../../assets/portafolio/proyecto6/Rene-08.jpg";

const Proyecto6 = () => {
  const currentProjectIndex = 5;
  const randomCards = getRandomCards(3, currentProjectIndex);
  const images = [
    imgProject1,
    imgProject2,
    imgProject3,
    imgProject4,
    imgProject5,
    imgProject6,
  ];
  const title = "René De Magdala";
  const info = "Branding | arte para disco";
  const texts = [
    "El objetivo del branding para René fué crear una imagen moderna y actual, que transmite su estilo musical y su personalidad. Para ello, se emplearon las siguientes estrategias: ",
    "Formas simples y tipografías lineales que transmiten una sensación de limpieza, orden, y encaja con la imagen de un músico profesional.",
    "Colores rojos y negros son clásicos, elegantes y dan mayor formalidad al diseño.",
  ];
  const frase =
    "“René es un guitarrista apasionado, que se entrega totalmente en cada una de sus presentaciones, logrando atraer, inspirar y seducir a quien lo escucha”";

  const color1 = "bg-[#691104]";
  const color2 = "bg-[#0e0e0b]";
  const color3 = "bg-[#21211f]";
  const color4 = "bg-[#991714]";
  const color5 = "bg-[#efeeee]";
  const color6 = "bg-[#ecc4c5]";
  const color7 = "bg-[#b51317]";
  const color8 = "bg-[#3c3c3b]";

  return (
    <Template
      banner={banner}
      images={images}
      title={title}
      info={info}
      texts={texts}
      frase={frase}
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      color5={color5}
      color6={color6}
      color7={color7}
      color8={color8}
      imgExtra1={imgExtra1}
      imgExtra2={imgExtra2}
      randomCards={randomCards}
    />
  );
};
export default Proyecto6;
